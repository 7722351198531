import '../css/app.css';

import $ from 'jquery';
import 'bootstrap';

$(function () {
    $('#loading').addClass('loaded');
    setTimeout(function () {
        $('#loading').hide();
    }, 2000);
});

$(function () {
    function modifyHeaderClass() {
        const divHeader = $('header.header_root');
        const divCssClass = 'scrolled';

        this.scrollY > 50 ? divHeader.addClass(divCssClass) : divHeader.removeClass(divCssClass);
    }

    window.addEventListener("scroll", modifyHeaderClass, false);

    const backToTopBtn = $('#back-to-top');
    backToTopBtn.click(function (e) {
        window.scrollTo({top: 0, behavior: 'smooth'});
    });

    function scrollFunction() {
        const styleClass = 'back-to-top-visible';
        this.scrollY > 500 ? backToTopBtn.addClass(styleClass) : backToTopBtn.removeClass(styleClass);
    }

    window.addEventListener("scroll", scrollFunction, false);
});

$(function () {
    var cookieconsent = initCookieConsent();

    cookieconsent.run({
        revision: 1,
        autorun: true,
        current_lang: document.getElementsByTagName('html')[0].lang,
        autoclear_cookies: true,
        page_scripts: true,

        languages: {
            en: {
                consent_modal: {
                    title: 'I use cookies',
                    description: 'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it.',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                        {col4: 'Description'},
                        {col5: 'Type'}
                    ],
                    blocks: [
                        {
                            title: 'Cookie usage',
                            description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.'
                        }, {
                            title: 'Strictly necessary cookies',
                            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            }
                        }, {
                            title: 'Analytics cookies',
                            description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [
                                {
                                    col1: '^_ga',
                                    col2: 'google.com',
                                    col3: '2 years',
                                    col4: 'description ...',
                                    col5: 'Permanent cookie',
                                    is_regex: true
                                },
                                {
                                    col1: '_gid',
                                    col2: 'google.com',
                                    col3: '1 day',
                                    col4: 'description ...',
                                    col5: 'Permanent cookie'
                                }
                            ]
                        }, {
                            title: 'More information',
                            description: 'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="#yourwebsite">contact me</a>.',
                        }
                    ]
                }
            },
            cs: {
                consent_modal: {
                    title: 'Používám cookies',
                    description: 'Tato webová stránka používá základní soubory cookie k zajištění správného fungování a sledovací soubory cookie, abychom pochopili, jak s nimi komunikujete.',
                    primary_btn: {
                        text: 'Souhlasím se vším',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                        {col4: 'Description'},
                        {col5: 'Type'}
                    ],
                    blocks: [
                        {
                            title: 'Cookie usage',
                            description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.'
                        }, {
                            title: 'Strictly necessary cookies',
                            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            }
                        }, {
                            title: 'Analytics cookies',
                            description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [
                                {
                                    col1: '^_ga',
                                    col2: 'google.com',
                                    col3: '2 years',
                                    col4: 'description ...',
                                    col5: 'Permanent cookie',
                                    is_regex: true
                                },
                                {
                                    col1: '_gid',
                                    col2: 'google.com',
                                    col3: '1 day',
                                    col4: 'description ...',
                                    col5: 'Permanent cookie'
                                }
                            ]
                        }, {
                            title: 'More information',
                            description: 'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="#yourwebsite">contact me</a>.',
                        }
                    ]
                }
            }
        }
    });
});